








import Vue from "vue";

export default Vue.component("QError", {
  props: {
    errors: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    errorKey: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      cloneErrors: this.errors,
      value: this.errors
    };
  },
  mounted() {
    this.updateValue();
  },
  computed: {
    test() {
      return Object.keys(this.errors);
    },
    status: function() {
      if (this.errors && Object.keys(this.errors).length > 0) {
        return "on";
      }

      return "";
    }
  },
  methods: {
    updateValue: function() {
      if (this.errorKey == null) {
        this.value = this.cloneErrors;
        return;
      }
      const keys = this.errorKey.split(".");
      let current = this.cloneErrors;
      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        current = current[key] || [];
      }

      this.value = current;
    }
  },
  watch: {
    errors: {
      deep: true,
      handler() {
        this.cloneErrors = this.errors;
        this.updateValue();
      }
    }
  }
});
